import { useNavigate } from 'react-router-dom';

import { Icon } from 'shared/components/ui';
import { DirectoryProfileFull } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import { DirectoryProfileAvatar } from '../DirectoryProfileAvatar';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

export const DirectoryProfileHeader = ({ profile }: Props) => {
	const navigate = useNavigate();

	const openLinkedin = () => {
		window.open(`https://www.linkedin.com/in/${profile.clean_linkedin}/`, '_blank');
	};

	const handleClick = () => {
		window.location.href = `https://app.muraena.ai/login?clean_linkedin=${profile.clean_linkedin}`;
	};
	return (
		<div className={styles.container}>
			<div className={styles.profileWrapper}>
				<DirectoryProfileAvatar key={profile.clean_linkedin} url={profile.clean_linkedin || ''} size={120} />
				<div className={styles.profile}>
					<p className={styles.profileName}>{getName(profile)}</p>
					<div className={styles.profileMeta}>
						<p className={styles.profileTitle}>
    						{profile.title}
    						{profile.company && ` | ${profile.company}`}
						</p>
						<p className={styles.profileTitle}>
							{profile.location && `${profile.location}, `}
							{profile.country}
						</p>
					</div>
					<Icon onClick={openLinkedin} icon="linkedin-logo" size={24} className={styles.linkedin} />
				</div>
			</div>
			<button onClick={handleClick} className={styles.button}>
				View contact details (it’s free)
			</button>
		</div>
	);
};
