import { DirectoryProfileFull } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

export const DirectoryProfileFAQ = ({ profile }: Props) => {
	const name = getName(profile);
	const QUESTION_ANSWERS = [];

	if (profile.emails_cleaned && profile.emails_cleaned.length > 0) {
		QUESTION_ANSWERS.push({
			question: `What is ${getName(profile)} email address?`,
			answer: `${getName(profile)}'s primary email address is ${
				profile.emails_cleaned[0]
			}. To view the full verified email and additional contact details, sign up for free with Muraena.`
		});
	}
	if (profile.phone_numbers && profile.phone_numbers.length > 0) {
		QUESTION_ANSWERS.push({
			question: `What is ${getName(profile)} mobile phone number?`,
			answer: `${getName(profile)}'s phone number is ${
				profile.phone_numbers[0]
			}. To access the full verified number, create a free Muraena account.`
		});
	}
	if (profile.title && profile.company) {
		const filteredCompanies = profile.exp?.filter(
			company => company.company === profile.company && company.current
		);
		let industry: string = '';
		if (filteredCompanies && filteredCompanies.length > 0) {
			industry = filteredCompanies[0].industry || '';
		}
		let answer = `${getName(profile)} is a ${profile.title} at ${profile.company}`;
		if (!!industry) {
			answer += `, a company specializing in ${industry}.`;
		} else {
			answer += '.';
		}

		QUESTION_ANSWERS.push({
			question: `What company does ${getName(profile)} work for?`,
			answer: answer
		});
	}
	if (profile.edu && profile.edu.length > 0) {
		QUESTION_ANSWERS.push({
			question: `Where ${getName(profile)} graduated from?`,
			answer: `${getName(profile)} holds a degree in ${profile.edu[0].specialization} from ${
				profile.edu[0].campus
			}.`
		});
	}
	if (profile.emails_cleaned && profile.emails_cleaned.length > 0) {
		const email = profile.emails_cleaned[0];
		let phone_number = '';
		if (profile.phone_numbers && profile.phone_numbers.length > 0) {
			phone_number = profile.phone_numbers[0];
		}
		let answer = `To contact ${getName(profile)} directly, you can use the email address ${email}`;
		if (!!phone_number) {
			answer += ` or call ${phone_number}`;
		}
		answer += '. Complete contact information is available upon registration with Muraena.';
		QUESTION_ANSWERS.push({
			question: `How can I directly contact ${getName(profile)}?`,
			answer: answer
		});
	}
	if (profile.immigrant) {
		QUESTION_ANSWERS.push({
			question: `Is ${getName(profile)} an immigrant?`,
			answer: `Yes, ${getName(profile)} is an immigrant and currently based in ${profile.country}`
		});
	}
	if (profile.summary) {
		QUESTION_ANSWERS.push({
			question: `Who is ${name}?`,
			answer: `${profile.summary}`
		});
	}

	return (
		<div className={styles.container}>
			<p className={styles.title}>Frequently Asked Questions about {getName(profile)}</p>
			<div className={styles.wrapper}>
				{QUESTION_ANSWERS.length > 0 &&
					QUESTION_ANSWERS.map(item => (
						<div>
							<p className={styles.question}>{item.question}</p>
							<p className={styles.answer}>{item.answer}</p>
						</div>
					))}
			</div>
		</div>
	);
};
