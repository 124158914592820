export const environment = {
	intercomId: 'c4ozkspm',
	env: process.env.REACT_APP_ENVIRONMENT || 'local',
	apiUrl: `${process.env.REACT_APP_BACKEND_URL}/api`,
	userUrl: `${process.env.REACT_APP_BACKEND_URL}/user`,
	directoryUrl: `${process.env.REACT_APP_BACKEND_URL}/sitemap`,
	mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || '',
	sentryDsn: process.env.REACT_APP_SENTRY_DSN || '',
	releaseHash: process.env.REACT_APP_GIT_SHA || 'unknown'
};
