import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { DirectoryProfileFull, DirectoryProfileExp } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import { DirectoryProfileCompanyLogo } from '../DirectoryProfileCompanyLogo';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

const formatDate = (date?: string) => {
	if (!date) return '';
	const dateObj = new Date(date);
	return dateObj.toLocaleString('en-US', { month: 'long', year: 'numeric' });
};

const formatEndDate = (exp: DirectoryProfileExp) => {
	if (exp.current) return 'Present';
	return formatDate(exp.endDate);
};

const getDateRange = (exp: DirectoryProfileExp) => {
	const startDate = formatDate(exp.startDate);
	const endDate = formatEndDate(exp);
	if (startDate && endDate) return `${startDate} - ${endDate}`;
	if (startDate) return startDate;
	if (endDate) return endDate;
};

export const DirectoryProfileExperience = ({ profile }: Props) => {
	const navigate = useNavigate();

	const handleCompanyClick = (url: string) => () => {
		window.location.href = `https://app.muraena.ai/login?company_domains=${url}`;
	};

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<p>{getName(profile)}</p>
				<p>Work Experience</p>
			</div>
			<div className={styles.body}>
				{profile.exp &&
					profile.exp.map(item => (
						<div className={styles.experience}>
							<DirectoryProfileCompanyLogo experience={item} />
							<div className={styles.experienceContent}>
								<div className={styles.experienceTitleWrapper}>
									<p className={styles.experienceTitle}>{item.title} at</p>
									<p
										onClick={handleCompanyClick(item.companyUrl_cleaned!)}
										className={cn(styles.experienceCompany, {
											[styles.experienceCompanyActive]: !!item.companyUrl_cleaned
										})}>
										{item.company}
									</p>
								</div>
								<p className={styles.experienceDates}>{getDateRange(item)}</p>
							</div>
						</div>
					))}
			</div>
		</div>
	);
};
