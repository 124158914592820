import { Icon } from 'shared/components/ui';
import { authLink } from 'services';
import { useLocalStorage } from 'shared/hooks';

import styles from './index.module.scss';

export const GoogleSocialButton = () => {
	const [promo, setPromo] = useLocalStorage('promo', {});
	let link = authLink;

	if ('pipedrive_code' in promo && promo['pipedrive_code'] && !authLink.includes('pipedrive_code')) {
		link += `&pipedrive_code=${promo['pipedrive_code']}`;
	}
	if ('hubspot_code' in promo && promo['hubspot_code'] && !authLink.includes('hubspot_code')) {
		link += `&hubspot_code=${promo['hubspot_code']}`;
	}
	if ('appsumo_code' in promo && promo['appsumo_code'] && !authLink.includes('appsumo_code')) {
		link += `&appsumo_code=${promo['appsumo_code']}`;
	}
	if ('coupon' in promo && promo['coupon'] && !authLink.includes('coupon')) {
		link += `&coupon=${promo['coupon']}`;
	}
	if ('deal' in promo && promo['deal'] && !authLink.includes('deal')) {
		link += `&deal=${promo['deal']}`;
	}
	if ('package' in promo && promo['package'] && !authLink.includes('package')) {
		link += `&package=${promo['package']}`;
	}
	if ('clean_linkedin' in promo && promo['clean_linkedin'] && !authLink.includes('clean_linkedin')) {
		link += `&clean_linkedin=${promo['clean_linkedin']}`;
	}
	if ('company_domains' in promo && promo['company_domains'] && !authLink.includes('company_domains')) {
		link += `&company_domains=${promo['company_domains']}`;
	}

	const submitForm = () => {
		window.location.href = link;
	};

	return (
		<button className={styles.button} onClick={submitForm}>
			<Icon className={styles.buttonIcon} icon="google-logo" size={20} />
			Continue with Google
		</button>
	);
};
