import { useGetDirectoriesQuery, useDirectoryPrefetch } from 'services';

interface Props {
	chapter: number;
	page: number;
}

export const useGetDirectories = ({ chapter, page }: Props) => {
	const prefetchPage = useDirectoryPrefetch('getDirectories');

	const { data, isFetching, isError, refetch } = useGetDirectoriesQuery({
		chapter: chapter,
		page: page
	});

	prefetchPage({ chapter: chapter, page: page + 1 });

	return { data: data, isFetching, isError, refetch };
};
