import { useNavigate } from 'react-router-dom';

import { Icon } from 'shared/components/ui';
import { DirectoryProfileFull } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import { DirectoryColleagueAvatar } from '../DirectoryColleagueAvatar';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

export const DirectoryProfileContacts = ({ profile }: Props) => {
	const navigate = useNavigate();

	const handleClick = () => {
		window.location.href = `https://app.muraena.ai/login?clean_linkedin=${profile.clean_linkedin}`;
	};

	const handleColleagueClick = (url: string) => () => {
		navigate(`/profile/${url}`);
	};

	return (
		<div className={styles.container}>
			<div className={styles.wrapper}>
				<div className={styles.body}>
					<p className={styles.title}>{profile.first_name}`s contact details</p>
					<div className={styles.contacts}>
						{profile.phone_numbers && profile.phone_numbers.length > 0 && (
							<div className={styles.row}>
								<Icon icon="phone" size={20} className={styles.icon} />
								<div className={styles.column}>
									{profile.phone_numbers.map(phone => (
										<p className={styles.text}>{phone}</p>
									))}
								</div>
							</div>
						)}
						{profile.emails_cleaned && profile.emails_cleaned.length > 0 && (
							<div className={styles.row}>
								<Icon icon="mail" size={20} className={styles.icon} />
								<div className={styles.column}>
									{profile.emails_cleaned.map(email => (
										<p className={styles.text}>{email}</p>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
				{profile.colleagues && profile.colleagues.length > 0 && (
					<div className={styles.body}>
						<p className={styles.title}>Colleagues</p>
						<div className={styles.colleagues}>
							{profile.colleagues.map(item => (
								<div key={item.url} className={styles.colleaguesWrapper}>
									<DirectoryColleagueAvatar url={item.url} size={44} />
									<div className={styles.column}>
										<a 
											href={`/profile/${item.url}`}
											target="_blank"
											rel="noopener noreferrer"
											className={styles.name}
										>
											{getName(item)}
										</a>
										<p className={styles.text}>{item.title}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				<button onClick={handleClick} className={styles.button}>
					View Contact details (it's free)
				</button>
			</div>
		</div>
	);
};
