import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LandingHeader } from 'shared/components/LandingHeader';
import { LandingFooter } from 'shared/components/LandingFooter';
import { useGetDirectories } from 'features/directory';
import { getName, DirectorySkeleton } from 'features/directory';
import { Pagination } from 'shared/components/ui';
import type { PaginationProps } from 'antd';

import styles from './index.module.scss';

export const Directory = () => {
	const { chapter } = useParams<{ chapter: string }>();

	const [currentPage, setCurrentPage] = useState<number>(1);

	const navigate = useNavigate();
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [chapter, currentPage]);

	useEffect(() => {
		setCurrentPage(1);
		window.scrollTo(0, 0);
	}, [chapter]);

	const { data, isFetching, isError, refetch } = useGetDirectories({
		chapter: parseInt(chapter!),
		page: currentPage
	});

	const handleClick = (url: string) => () => {
		navigate(`/profile/${url}`);
	};

	const onChange: PaginationProps['onChange'] = page => {
		setCurrentPage(page);
		window.scrollTo(0, 0);
	};

	return (
		<>
			<LandingHeader />
			<div className={styles.container}>
				<h1 className={styles.title}>Directory</h1>
				{data && !isFetching && (
					<div className={styles.gridContainer}>
						{data.results.map(profile => (
							<a 
								href={`/profile/${profile.url}`}
								onClick={(e) => {
									e.preventDefault();
									handleClick(profile.url)();
								}}
								key={profile.url} 
								className={styles.name}
							>
								{getName(profile)}
							</a>
						))}
					</div>
				)}
				{isFetching && <DirectorySkeleton />}
				{data && <Pagination current={currentPage} total={data.total_subpages!} onChange={onChange} />}
			</div>
			<LandingFooter />
		</>
	);
};
