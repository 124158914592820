import { SkeletonRow } from 'shared/components/ui';

import styles from './index.module.scss';

export const DirectorySkeleton = () => {
	return (
		<div className={styles.gridContainer}>
			{Array.from({ length: 100 }).map((_, index) => (
				<div className={styles.wrapper}>
					<SkeletonRow key={index} width={200} height={20} />
				</div>
			))}
		</div>
	);
};
