import { DirectoryProfileFull, DirectoryProfileEdu } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

const formatDate = (date?: string) => {
	if (!date) return '';
	const dateObj = new Date(date);
	return dateObj.toLocaleString('en-US', { month: 'long', year: 'numeric' });
};

const getDateRange = (exp: DirectoryProfileEdu) => {
	const startDate = formatDate(exp.startDate);
	const endDate = formatDate(exp.endDate);
	if (startDate && endDate) return `${startDate} - ${endDate}`;
	if (startDate) return startDate;
	if (endDate) return endDate;
};

export const DirectoryProfileEducation = ({ profile }: Props) => {
	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<p>{getName(profile)}</p>
				<p>Education</p>
			</div>
			<div className={styles.body}>
				{profile.edu &&
					profile.edu.map(item => (
						<div className={styles.content}>
							<p className={styles.university}>
                    {item.universityUrl ? (
                        <a href={item.universityUrl} target="_blank" rel="noopener noreferrer">{item.campus}</a>
                    ) : (
                        item.campus
                    )}
                    {item.major ? `, ${item.major}` : ''}
                </p>
							<p className={styles.dates}>{getDateRange(item)}</p>
						</div>
					))}
			</div>
		</div>
	);
};
