import { useParams } from 'react-router-dom';
import { LandingHeader } from 'shared/components/LandingHeader';
import { LandingFooter } from 'shared/components/LandingFooter';
import { useEffect } from 'react';
import { useGetDirectoryProfileQuery } from 'services';
import { getName } from 'features/directory';
import {
  DirectoryProfileContacts,
  DirectoryProfileEducation,
  DirectoryProfileExperience,
  DirectoryProfileFAQ,
  DirectoryProfileHeader,
  DirectoryProfileSummary
} from 'features/directory';
import { DirectoryProfileFull } from 'shared/generated-models'; // Add this import explicitly
import styles from './index.module.scss';

const injectSchema = (profile: DirectoryProfileFull) => {
  document.querySelectorAll('script[type="application/ld+json"]').forEach(el => el.remove());
  const schemaData = {
    '@context': 'https://schema.org/',
    '@type': 'Person',
    'name': `${profile.first_name || ''} ${profile.last_name || ''}`.trim(),
    'description': profile.summary || undefined,
    'jobTitle': profile.title,
    'worksFor': profile.company
      ? {
          '@type': 'Organization',
          'name': profile.company,
          'sameAs': profile.clean_company_linkedin_url || undefined
        }
      : undefined,
    'email':
      profile.emails_cleaned && profile.emails_cleaned.length > 0
        ? `mailto:${profile.emails_cleaned[0]}`
        : undefined,
    'telephone':
      profile.phone_numbers && profile.phone_numbers.length > 0
        ? profile.phone_numbers[0]
        : undefined,
    'alumniOf':
      profile.edu && profile.edu.length > 0
        ? {
            '@type': 'CollegeOrUniversity',
            'name': profile.edu[0].campus
          }
        : undefined,
    'sameAs': profile.clean_linkedin ? [profile.clean_linkedin] : undefined,
    'url': window.location.href,
    'address': profile.location
      ? {
          '@type': 'PostalAddress',
          'addressLocality': profile.location,
          'addressCountry': profile.country
        }
      : undefined
  };

  const cleanSchema = (obj: any) => {
    Object.keys(obj).forEach(key => {
      if (obj[key] === undefined || obj[key] === null) delete obj[key];
      else if (typeof obj[key] === 'object') cleanSchema(obj[key]);
    });
  };

  cleanSchema(schemaData);

  const script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = JSON.stringify(schemaData);
  document.head.appendChild(script);
};

export const DirectoryProfile = () => {
  const { url } = useParams<{ url: string }>();

  const { data, isFetching, isError, refetch } = useGetDirectoryProfileQuery(url!);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [url]);

  useEffect(() => {
    if (data) {
      injectSchema(data);

      // Dynamic Page Title
      document.title = `${data.first_name} ${data.last_name} | ${data.title}`;

      // Dynamic Meta Description
      const description = data.summary || `Discover profile of ${data.first_name} ${data.last_name} on Muraena.`;
      let metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', description);
      } else {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', description);
        document.head.appendChild(metaDescription);
      }
    }
  }, [data]);

  return (
    <>
      <LandingHeader />
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.headerDirectory}>Directory</p>
          <p className={styles.headerDirectory}>{'>'}</p>
          {data && <p className={styles.headerName}>{getName(data)}</p>}
        </div>
        {data && (
          <div className={styles.profile}>
            <DirectoryProfileHeader profile={data} />
            <div className={styles.profileBody}>
              <div className={styles.profileContent}>
                <DirectoryProfileSummary profile={data} />
                {data.exp && data.exp.length > 0 && <DirectoryProfileExperience profile={data} />}
                {data.edu && data.edu.length > 0 && <DirectoryProfileEducation profile={data} />}
                <DirectoryProfileFAQ profile={data} />
              </div>
              <DirectoryProfileContacts profile={data} />
            </div>
          </div>
        )}
      </div>
      <LandingFooter />
    </>
  );
};
