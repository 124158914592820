import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { DirectoryParams, DirectoryProfileFull, PaginatedDirectories } from 'shared/generated-models';
import { environment } from 'environments/environment';

export const directoryService = createApi({
	reducerPath: 'directoryService',
	baseQuery: fetchBaseQuery({ baseUrl: environment.directoryUrl }),
	tagTypes: ['Directory', 'DirectoryProfile'],
	endpoints: builder => ({
		getDirectories: builder.query<PaginatedDirectories, DirectoryParams>({
			query: params => ({
				url: `/list/${params.chapter && params.page ? `?chapter=${params.chapter}&page=${params.page}` : ''}`,
				method: 'GET',
				invalidatesTags: ['Directory']
			})
		}),
		getDirectoryProfile: builder.query<DirectoryProfileFull, string>({
			query: url => ({
				url: `/profile/${url}`,
				method: 'GET',
				invalidatesTags: ['DirectoryProfile']
			})
		})
	})
});

export const { useGetDirectoriesQuery, useGetDirectoryProfileQuery, usePrefetch: useDirectoryPrefetch } = directoryService;
