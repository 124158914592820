import mixpanel from 'mixpanel-browser';

import { environment } from 'environments/environment';
//import { BillingData, User, ProgAISearchResult, ServiceEnum } from 'shared/generated-models';

import { AnalyticsEvents, AnalyticsProperties } from './models';

export class Analytics {
	static init() {
		mixpanel.init(environment.mixpanelToken);
	}

	static trackLogin({ isNew, id, fromPublicSEOPage }: { isNew: boolean; id: string; fromPublicSEOPage: boolean }) {
		mixpanel.identify(id);
		const lastLogin = new Date().toISOString();
		mixpanel.people.set({
			'Last Login': lastLogin
		});
		if (fromPublicSEOPage)
			mixpanel.people.set({
				'From Public SEO Page': fromPublicSEOPage
			});
		mixpanel.people.increment('# of logins');
	}

	// 	static trackSearch(searchResult: ProgAISearchResult) {
	// 		const { results_count, request_fields } = searchResult;
	//
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SEARCHES);
	//
	// 		if (request_fields) {
	// 			const metadata = {
	// 				[AnalyticsProperties.CANDIDATES_COUNT]: results_count,
	// 				[AnalyticsProperties.REQUIRED]: !!request_fields.required_skills,
	// 				[AnalyticsProperties.BONUS]: !!request_fields.bonus_skills,
	// 				[AnalyticsProperties.TITLE]: !!request_fields.job_titles,
	// 				[AnalyticsProperties.EMPLOYMENT]: !!request_fields.yo_employment,
	// 				[AnalyticsProperties.EXPERIENCE]: !!request_fields.yo_experience,
	// 				[AnalyticsProperties.SENIORITY]: !!request_fields.seniority,
	// 				[AnalyticsProperties.LOCATION]: !!request_fields.locations,
	// 				[AnalyticsProperties.COUNTRY]: !!request_fields.countries,
	// 				[AnalyticsProperties.REGION]: !!request_fields.regions,
	// 				[AnalyticsProperties.SUBREGION]: !!request_fields.subregions,
	// 				[AnalyticsProperties.COMPANIES]: !!request_fields.companies,
	// 				[AnalyticsProperties.EXCLUDED]: !!request_fields.companies_to_exclude,
	// 				[AnalyticsProperties.REPOS]: !!request_fields.github_repos,
	// 				[AnalyticsProperties.PAGE_NUMBER]: request_fields.page || 0
	// 			};
	//
	// 			mixpanel.track(AnalyticsEvents.SEARCH, metadata);
	// 			// window.Intercom('trackEvent', AnalyticsEvents.SEARCH, metadata);
	// 		} else {
	// 			// window.Intercom('trackEvent', AnalyticsEvents.SEARCH, {
	// 			// 	[AnalyticsProperties.CANDIDATES_COUNT]: results_count
	// 			// });
	// 		}
	// 	}
	//
	// 	static trackProfileOpening(id: number) {
	// 		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };
	//
	// 		mixpanel.track(AnalyticsEvents.OPEN_PROFILE, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PROFILE_OPENS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.OPEN_PROFILE, metadata);
	// 	}
	//
	// 	static trackRegistration(user: User) {
	// 		mixpanel.register({
	// 			[AnalyticsProperties.FIRST_NAME]: user.first_name,
	// 			[AnalyticsProperties.LAST_NAME]: user.last_name,
	// 			[AnalyticsProperties.BILLING_PLAN]: user.billing_plan
	// 		});
	//
	// 		mixpanel.people.set({
	// 			[AnalyticsProperties.REGISTRATION_DATE]: new Date().toISOString(),
	// 			[AnalyticsProperties.MIXPANEL_FIRST_NAME]: user.first_name,
	// 			[AnalyticsProperties.MIXPANEL_LAST_NAME]: user.last_name,
	// 			[AnalyticsProperties.MIXPANEL_NAME]: `${user.first_name} ${user.last_name}`,
	// 			[AnalyticsProperties.MIXPANEL_EMAIL]: user.email,
	// 			[AnalyticsProperties.COMPANY]: user.company
	// 		});
	//
	// 		const metadata = {
	// 			[AnalyticsProperties.FIRST_NAME]: user.first_name,
	// 			[AnalyticsProperties.LAST_NAME]: user.last_name,
	// 			[AnalyticsProperties.BILLING_PLAN]: user.billing_plan,
	// 			[AnalyticsProperties.REGISTRATION_DATE]: new Date().toISOString(),
	// 			[AnalyticsProperties.EMAIL]: user.email,
	// 			[AnalyticsProperties.COMPANY]: user.company
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.REGISTRATION, metadata);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.REGISTRATION, metadata);
	// 	}
	//
	// 	static trackProfileShare(id: number) {
	// 		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };
	//
	// 		mixpanel.track(AnalyticsEvents.SHARE_PROFILE, { [AnalyticsProperties.PROFILE_ID]: id });
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SHARINGS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.SHARE_PROFILE, metadata);
	// 	}
	//
	// 	static trackSharedOpening(id: number) {
	// 		const metadata = { [AnalyticsProperties.PROFILE_ID]: id };
	//
	// 		mixpanel.track(AnalyticsEvents.OPEN_SHARED_PROFILE, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_SHARED_PROFILE_OPENS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.OPEN_SHARED_PROFILE, metadata);
	// 	}
	//
	// 	static trackCsvExport(count: number, source: 'Search' | 'Project' | 'Contacts') {
	// 		const metadata = {
	// 			[AnalyticsProperties.NUMBER_OF_EXPORTED_PROFILES]: count,
	// 			[AnalyticsProperties.SOURCE]: source
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.EXPORT, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_EXPORTS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.EXPORT, metadata);
	// 	}
	//
	// 	static trackLinkedinClick(id: number, link: string) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROFILE_ID]: id,
	// 			[AnalyticsProperties.SOURCE]: 'Linkedin',
	// 			[AnalyticsProperties.LINK]: link
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.CLICKED_ON_LINKEDIN, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_LINKEDIN, metadata);
	// 	}
	//
	// 	static trackGithubClick(id: number, link: string) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROFILE_ID]: id,
	// 			[AnalyticsProperties.SOURCE]: 'Github',
	// 			[AnalyticsProperties.LINK]: link
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.CLICKED_ON_GITHUB, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_GITHUB, metadata);
	// 	}
	//
	// 	static trackTwitterClick(id: number, link: string) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROFILE_ID]: id,
	// 			[AnalyticsProperties.SOURCE]: 'Twitter',
	// 			[AnalyticsProperties.LINK]: link
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.CLICKED_ON_TWITTER, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REDIRECTS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.CLICKED_ON_TWITTER, metadata);
	// 	}
	//
	// 	static trackPaymentSuccess(billingData: BillingData) {
	// 		const metadata = {
	// 			[AnalyticsProperties.BILLING_PLAN]: billingData.billing_plan,
	// 			[AnalyticsProperties.INTERVAL]: billingData.interval
	// 		};
	//
	// 		mixpanel.register(metadata);
	// 		mixpanel.track(AnalyticsEvents.SUBSCRIPTION, metadata);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.SUBSCRIPTION, metadata);
	// 	}
	//
	// 	static trackGetContact(id: number) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROFILE_ID]: id
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.GET_CONTACT, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_REVEALS);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.GET_CONTACT, metadata);
	// 	}
	//
	// 	static trackEmailCopy(id: number, email: string) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROFILE_ID]: id,
	// 			[AnalyticsProperties.SOURCE]: 'Email',
	// 			[AnalyticsProperties.LINK]: email
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.COPIED_EMAIL, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_COPIES);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.COPIED_EMAIL, metadata);
	// 	}
	//
	// 	static trackAddingCandidatesToProject({
	// 		projectId,
	// 		candidatesCount
	// 	}: {
	// 		projectId?: number;
	// 		candidatesCount?: number;
	// 	}) {
	// 		const metadata = {
	// 			[AnalyticsProperties.PROJECT_ID]: projectId,
	// 			[AnalyticsProperties.NUMBER_OF_CANDIDATES]: candidatesCount
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.ADD_TO_PROJECT, metadata);
	// 		// window.Intercom('trackEvent', AnalyticsEvents.ADD_TO_PROJECT, metadata);
	// 	}
	//
	// 	static trackPushToOutreach({ service, candidatesCount }: { service: ServiceEnum; candidatesCount: number }) {
	// 		const metadata = {
	// 			[AnalyticsProperties.SERVICE]: service,
	// 			[AnalyticsProperties.NUMBER_OF_CANDIDATES]: candidatesCount
	// 		};
	//
	// 		mixpanel.track(AnalyticsEvents.PUSH_TO_OUTREACH, metadata);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PUSHED, candidatesCount);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PUSHES);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.PUSH_TO_OUTREACH, metadata);
	// 	}
	//
	// 	static trackParsedText() {
	// 		mixpanel.track(AnalyticsEvents.PARSED_TEXT);
	// 		mixpanel.people.increment(AnalyticsProperties.NUMBER_OF_PARSED);
	//
	// 		// window.Intercom('trackEvent', AnalyticsEvents.PARSED_TEXT);
	// 	}
}
