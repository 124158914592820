import { useState } from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import styles from './index.module.scss';

interface Props {
	url: string;
	size: number;
}

export const DirectoryProfileAvatar = ({ url, size }: Props) => {
	const [imageError, setImageError] = useState(false);

	return (
		<>
			{!imageError ? (
				<img
					width={size}
					height={size}
					className={styles.logo}
					src={`https://contacts.muraena.ai/sitemap_avatars/${url}.png`}
					alt="logo"
					onError={() => setImageError(true)}
				/>
			) : (
				<Avatar icon={<UserOutlined />} size={size} />
			)}
		</>
	);
};
