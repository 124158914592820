import { Icon } from 'shared/components/ui';
import { DirectoryProfileFull } from 'shared/generated-models';
import { getName } from 'features/directory/utils';

import styles from './index.module.scss';

interface Props {
	profile: DirectoryProfileFull;
}

export const DirectoryProfileSummary = ({ profile }: Props) => {
	const generalTags = [];
	if (profile.professional_adaptability) generalTags.push(profile.professional_adaptability);
	if (profile.technical_background) generalTags.push('Technical Background');
	if (profile.leadership_experience) generalTags.push('Leadership Experience');
	if (profile.employment_stability) generalTags.push(profile.employment_stability);
	if (profile.immigrant) generalTags.push('Immigrant');

	return (
		<div className={styles.container}>
			<div className={styles.title}>
				<p>{getName(profile)}</p>
				<p>AI Summary</p>
			</div>
			<div className={styles.body}>
				<p className={styles.summary}>{profile.summary}</p>
				<div className={styles.content}>
					{generalTags.length > 0 && (
						<div className={styles.tags}>
							<p className={styles.tagsTitle}>General</p>
							<div className={styles.tagsWrapper}>
								{generalTags.map(item => (
									<p className={styles.tag}>{item}</p>
								))}
							</div>
						</div>
					)}
					{profile.professional_interests && (
						<div className={styles.tags}>
							<p className={styles.tagsTitle}>Professional interests</p>
							<div className={styles.tagsWrapper}>
								{profile.professional_interests.map(item => (
									<p className={styles.tag}>{item}</p>
								))}
							</div>
						</div>
					)}
					{profile.organizational_experience && (
						<div className={styles.tags}>
							<p className={styles.tagsTitle}>Organisational experience</p>
							<div className={styles.tagsWrapper}>
								{profile.organizational_experience.map(item => (
									<p className={styles.tag}>{item}</p>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
