import { useEffect, useState } from 'react';
import { DirectoryProfileExp } from 'shared/generated-models';
import { CandidateProfileAvatar } from 'shared/components';

import styles from './index.module.scss';

interface Props {
	experience: DirectoryProfileExp;
}

export const DirectoryProfileCompanyLogo = ({ experience }: Props) => {
	const [logo, setLogo] = useState<string | null>(null);

	useEffect(() => {
		const fetchLogo = async () => {
			try {
				const logoUrl = `https://logo.clearbit.com/${experience.companyUrl_cleaned}`;
				const response = await fetch(logoUrl);
				if (response.ok) {
					setLogo(logoUrl);
				} else {
					setLogo(null);
				}
			} catch (error) {
				console.error('Failed to fetch logo:', error);
				setLogo(null);
			}
		};

		fetchLogo();
	}, [experience]);

	return (
		<>
			{logo ? (
				<img className={styles.logo} src={logo} alt="logo" />
			) : (
				<CandidateProfileAvatar size={32} className={styles.job}>
					💼
				</CandidateProfileAvatar>
			)}
		</>
	);
};
